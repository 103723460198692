import React from 'react';
import { useRouter } from 'next/router'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { PiTrophy, PiToilet } from 'react-icons/pi';
import Image from 'next/image';
import {
    Grid,
    Box,
    H2,
    H3,
} from '@saladbob/sassafras'

import { Tournament } from '../../types'

type PropsLabel = {
    tournament: Tournament,
    imageWidth?: number,
    fromNow?: boolean,
    trophy?: boolean,
}

dayjs.extend(relativeTime);

const TournamentLabel: React.FC<PropsLabel> = ({ tournament, imageWidth, fromNow, trophy }) => {
    const router = useRouter();

    const handleClick = () => {
        router.push(`/local/${tournament.host.slug}`);
    }

    return (
        <Grid
            txtColor="white"
            bgColor="secondary700"
            columns="min-content 1fr min-content"
            columnGap="sm"
            role="button"
            onClick={handleClick}
            depth="lg"
            bdWidth={[0, 0, 0, 'lg']}
            bdColor="tertiary"
        >
            <Box width={imageWidth}>
                {tournament.image && (
                    <Image
                        src={tournament.image.sizes.thumbnail.url}
                        alt={tournament.title}
                        placeholder={tournament.image.blurDataUrl ? 'blur' : 'empty'}
                        blurDataURL={tournament.image.blurDataUrl}
                        width={imageWidth}
                        height={imageWidth}
                        loading="lazy"
                        style={{
                            objectFit: 'cover',
                        }}
                    />
                )}
            </Box>
            <Box verticalAlign="center" textAlign="left">
                <H2 uppercase font="p">{tournament.title}</H2>
                <H3 uppercase font="p" weight="normal" color="tertiary">
                    {dayjs(tournament.start).format('MMM DD')}-{dayjs(tournament.end).format('DD')}
                </H3>
            </Box>
            {fromNow && (
                <Box verticalAlign="center" textAlign="right" pe="md" className="hide-on-mobile">
                    <H3 font="small" weight="normal" color="rgba(255, 255, 255, 0.5)" uppercase nowrap>
                        {dayjs(tournament.start).fromNow(true)} away!
                    </H3>
                </Box>
            )}

            {trophy && (
                <Box verticalAlign="center" textAlign="right" pe="md" txtColor="tertiary" className="hide-on-mobile">
                    <PiTrophy size="40px" />
                </Box>
            )}
        </Grid>
    )
}

TournamentLabel.defaultProps = {
    imageWidth: 80,
}

export default TournamentLabel;
import React, { useState, useRef, useEffect, useMemo } from "react";
import {
    Box,
    Btn,
    FieldSelect,
} from '@saladbob/sassafras'

import { Responsive } from "../../types";

type Tab = {
    label: string,
    id: string,
    onClick?: Function,
}

type Props = {
    tabs: Tab[],
    label: string,
    selected: string,
    placeholder?: string,
    color?: string,
    txtColor?: string,
    onChange: Function,
    children?: React.ReactNode,
    mb?: Responsive,
    align?: string,
};

const Tabs: React.FC<Props> = ({
    tabs,
    label,
    onChange,
    selected,
    placeholder,
    color,
    txtColor,
    mb,
    align,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const containerWidth = useRef(0);
    const selectRef = useRef(false);
    const [select, setSelect] = useState(false);
    const selectOptions = useMemo(() => tabs.map((tab) => ({ label: tab.label, value: tab.id })), [tabs]);
    const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        if (value) {
            onChange(value);
        }
    }

    function handleResize() {
        if (containerRef.current) {
            const { scrollWidth, clientWidth } = containerRef.current;
            if (!selectRef.current) {
                containerWidth.current = scrollWidth;
            }

            if (!selectRef.current && containerWidth.current > clientWidth) {
                setSelect(true);
            } else if (containerWidth.current < clientWidth) {
                setSelect(false);
            }
        }
    };

    useEffect(() => {
        selectRef.current = select;
    }, [select]);

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <Box
            mb={mb}
            maxWidth="100%"
            width="100%"
            rounded="lg"
            justify={align}
            ref={containerRef}
            style={{ overflowX: 'scroll'}}
        >
            {select && (
                <Box>
                    <FieldSelect
                        value={selected ? selected : ''}
                        placeholder={placeholder}
                        onChange={handleChange}
                        bgColor="transparent"
                        txtColor={txtColor}
                        bdColor={color}
                        bdWidth="2px"
                        options={selectOptions}
                        ariaLabel={label}
                    />
                </Box>
            )}
            {!select && (
                <Box display="flex" width="min-content" justify={align}>
                    {tabs.map((tab) => (
                        <Btn
                            key={tab.id}
                            onClick={() => onChange(tab.id)}
                            color={tab.id === selected ? color : 'transparent'}
                            txtColor={tab.id === selected ? null : txtColor}
                            outline={tab.id  === selected}
                            size="sm"
                            me="md"
                            width="min-content"
                            style={{ flexShrink: 0 }}
                        >
                            {tab.label}
                        </Btn>
                    ))}
                </Box>
            )}
        </Box>
    )
}

Tabs.defaultProps = {
    color: 'secondary',
    txtColor: 'secondary',
    mb: 'md',
}

export default Tabs;


import React, { useEffect } from 'react';
import { useUserDispatch } from './providers/UserProvider';
import { useEnvDispatch } from './providers/EnvProvider';
import { fetchUser } from '../hooks/useSwr';
import { User } from '../types';

import Header from './navigation/Header';
import Nav from './navigation/Nav';

export default ({ children, site, menu, mobileMenu }) => {
    const userDispatch = useUserDispatch();
    const envDispatch = useEnvDispatch();
    const { user, isLoading, isError } = fetchUser();

    const setUser = (u: User) => {
        userDispatch({
            type: 'replace',
            user: u,
        });
    };

    useEffect(() => {
        setUser(isLoading ? { isLoading: true } : user);
    }, [user, isLoading, isError]);

    useEffect(() => {
        envDispatch({
            type: 'setProp',
            key: 'isServer',
            value: false,
        });
    }, []);

    return (
        <>
            <Header
                logo={site.logo.url}
                defaultProfile={site.defaultProfile}
                nav={(
                    <Nav color={menu.color || 'primary'} nav={[...menu.menu]} weight="bold" />
                )}
                mobileNav={(
                    <Nav nav={[...mobileMenu.menu]} color="white" weight="bold" vertical/>
                )}
            />
            {children}
        </>
    )
}
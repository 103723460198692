import React, { useMemo } from 'react';
import Img from "next/image";
import { Upload } from '../../types';
import { Box } from '@saladbob/sassafras'
import Blur from '../svg/Blur';
import type Responsive from '../../types/Responsive';

type Props = {
    image: Upload,
    alt: string,
    className?: string,
    fill?: boolean,
    width?: Responsive,
    height?: Responsive,
    minWidth?: Responsive,
    minHeight?: Responsive,
    maxWidth?: Responsive,
    maxHeight?: Responsive, 
    bgColor?: string,
    size?: string,
    sizes?: string,
    pb?: Responsive,
    mb?: Responsive,
    rounded?: Responsive,
    bdWidth?: Responsive,
    bdColor?: Responsive,
    margin?: Responsive,
    padding?: Responsive,
    aspectRatio?: string,
    depth?: string,
    loading?: 'lazy' | 'eager',
    objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down',
}

const Image : React.FC<Props> = ({
    image,
    alt,
    size = 'hd',
    fill = true,
    loading = 'lazy',
    className,
    width = '100%',
    minWidth,
    maxWidth,
    height = '100%',
    minHeight,
    maxHeight,
    bgColor,
    margin,
    padding,
    pb,
    mb,
    rounded,
    bdWidth,
    bdColor,
    depth,
    aspectRatio,
    objectFit = 'cover',
}) => {
    const style = useMemo(() => {
        if (!aspectRatio) {
            return {};
        }
        return {
            aspectRatio,
        }
    }, [aspectRatio]);

    const imageStyle = useMemo(() => {
        return {
            objectFit,
            aspectRatio,
        }
    }, [objectFit, aspectRatio]);

    if (!image) {
        return null
    }

    return (
        <Box
            className={className}
            width={width}
            height={height}
            maxWidth={maxWidth}
            minWidth={minWidth}
            minHeight={minHeight}
            maxHeight={maxHeight}
            bgColor={bgColor || image.color}
            rounded={rounded}
            bdWidth={bdWidth}
            bdColor={bdColor}
            margin={margin}
            padding={padding}
            pb={pb}
            mb={mb}
            depth={depth}
            overflow="hidden"
            align="stretch"
            style={style}
        >
            <Img
                src={size ? image.sizes?.[size]?.url || image.url : image.url}
                alt={alt}
                fill={fill}
                draggable={false}
                loading={loading}
                style={imageStyle}
            />
        </Box>
    )
};

export default Image;